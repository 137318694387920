import { React, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from '@tanstack/react-query'
import Cookies from 'js-cookie';

import ApiService from "../../../../Services/apiService";

import FormInput from "../../../../Components/FormInput";
import DialogTemplate from "../../../../Components/DialogTemplate";

import { validation } from "../../../../Util/Validation";


import main_css from "../../../../assets/main_css/main.module.css";

const StudentSignUpForm = ({ token }) => {
  const navigate = useNavigate();
  const apiService = new ApiService();
  const queryClient = useQueryClient();
  let organization;

  const montedRef = useRef(true)
  const [openDialog, setOpenDialog] = useState(null);
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [grade, setGrade] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [confirmPassVali, setConfirmPassVali] = useState({
    pattern: validation.password.pattern,
    message: validation.password.message,
  });

  const [parentEmail, setParentEmail] = useState("");
  const [parentValidation, setParentValidation] = useState({
    pattern: validation.email.pattern,
    message: validation.email.message,
  });
  const [phone, setPhone] = useState("");
  // Utilized useState for the purpose of setting error message on incorrect email verification code
  const [verifCodeErr, setVerifCodeErr] = useState({
    initial: true,
    close: true,
    classes: null,
    message: null,
  });

  useEffect(() => {
    return () => {
      montedRef.current = false
    }
  }, [])

  // Utilized useEffect for opening email_verif_dialog with error message after the state of verifCodeErr has been set
  useEffect(() => {
    if (verifCodeErr.initial === false && verifCodeErr.close === false) {
      email_verif_dialog();
    }
  }, [verifCodeErr]);

  useEffect(() => {
    if (email === parentEmail) {
      setParentValidation({
        pattern: "err",
        message: "Parent email cannot be same as student email",
      });
    } else {
      setParentValidation({
        pattern: validation.email.pattern,
        message: validation.email.message,
      });
    }
  }, [parentEmail, email]);

  useEffect(() => {
    if (password !== confirmPass) {
      setConfirmPassVali({
        pattern: "err",
        message: "Your passwords do not match",
      });
    } else {
      setConfirmPassVali({
        pattern: validation.password.pattern,
        message: validation.password.message,
      });
    }
  }, [password, confirmPass]);

  // Sets emailVerifCode to the users input
  let emailVerifCode = "";
  const setEmailVerifCode = (e) => {
    emailVerifCode = e;
  };

  // function to delay between dialogs to achive drop down effect on 2nd dialog
  const drop_dialog = (dialog) => {
    setTimeout(() => {
      dialog();
    }, 100);
  };

  // Set var for dialogs to keep code neat
  const email_verif_dialog = () => {
    setOpenDialog({
      mainText: "Confirm Your Email",
      mainTextClass: "fs-2 px-3",
      subText: "Please Enter the code that has been emailed to you.",
      subTextClass: "fs-2 px-3 pt-0",
      input: {
        type: "text",
        placeholder: "000000",
        classes: `dialog_input ${verifCodeErr.classes}`,
        onChangeHandler: setEmailVerifCode,
        message: verifCodeErr.message,
      },
      button1Text: "Confirm Email",
      onClickButton1: createAccount,
      closeHandler: () => {
        setOpenDialog(null);
        setVerifCodeErr({
          initial: false,
          close: true,
          classes: null,
          message: null,
        });
      },
      buttonClass: "pb-2",
      duration: "1000ms",
    });
  };

  // Set var for dialogs to keep code neat
  const user_exists_dialog = () => {
    setOpenDialog({
      mainText: `There is already an account associated with this email address`,
      mainTextClass: "fs-2 px-lg-3",
      subText: "Try logging in or use another email address.",
      button1Text: "Login",
      onClickButton1: () => navigate("/login"),
      button2Text: "Back To Sign-Up",
      onClickButton2: () => setOpenDialog(null),
    });
  };

  // Set var for dialogs to keep code neat
  const error_dialog = () => {
    setOpenDialog({
      mainText: "Oops!",
      subText:
        "Something went wrong!\nPlease check the information you entered and try again.",
      subTextClass: "px-lg-3",
      button1Text: "Back To Sign-Up",
      onClickButton1: () => setOpenDialog(false),
      closeHandler: () => setOpenDialog(false),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpenDialog({
      mainText: "Loading.....\n Please Wait! ",
      closeHandler: () => setOpenDialog(null),
      duration: "0ms",
    });
    try {
      const [emailVerifResp, error] = await apiService.post({
        path: "/email_verif_code",
        data: {
          username: email,
          name: fname,
          token: token,
          role: 'student',
        },
        dataType: "sign_up",
      });

      //Sign up is locked and the user did not have a proper access token
      if (error && error.response.request.status === 401) {
        let token_err_dialog = {
          mainText: "Request Sent!",
          subText: "Thank you for signing up!\nWe are reviewing your account and will email you shortly with your new account!",
          button1Text: "Back To Home Page",
          onClickButton1: () => navigate("/login"),
        };
        if (token) {
          if (error.response.data.message === 'invalid') {
            token_err_dialog = {
              mainText: "Uh Oh!",
              subText: "Your link seems not to be working!\n Please contact support for a new link",
              button1Text: "Back To Home Page",
              onClickButton1: () => navigate("/login"),
            };
          }
          else if (error.response.data.message === 'expired') {
            token_err_dialog = {
              mainText: "Uh Oh!",
              subText: `Your link seems to be expired!\nPlease contact\n${error.response.data.email}\nfor a new link`,
              button1Text: "Back To Home Page",
              onClickButton1: () => navigate("/login"),
            };
          }
          else if (error.response.data.message === 'notFound') {
            token_err_dialog = {
              mainText: "Uh Oh!",
              subText: `We could not find your special link!\nPlease contact\n${error.response.data.email}\nfor a new link`,
              button1Text: "Back To Home Page",
              onClickButton1: () => navigate("/login"),
            };
          }
        }
        setOpenDialog(null);
        drop_dialog(() => setOpenDialog(token_err_dialog));
        setTimeout(() => {
          navigate("/");
        }, 15000);
      }
      // After response first setOpenDialog is set to null to close previous dialog and together with the drop_dialog function the 2nd dialog has a drop down effect
      else if (emailVerifResp && emailVerifResp.status === 200) {
        organization = emailVerifResp.data.organization;
        setOpenDialog(null);
        // drop_dialog(email_verif_dialog);
        createAccount();
      } else if (
        error.response &&
        error.response.data.message === "User exists"
      ) {
        setOpenDialog(null);
        drop_dialog(user_exists_dialog);
      } else {
        setOpenDialog(null);
        drop_dialog(error_dialog);
      }
    } catch (error) {
      console.log(error);
      setOpenDialog(null);
      drop_dialog(error_dialog);
    }
  };

  const createAccount = async (e) => {
    e.preventDefault();
    // setOpenDialog(null);
    setOpenDialog({
      mainText: "Hang Tight!\nwhile we sign you up.",
    });
    try {
      const [signUpResp, error] = await apiService.post({
        path: "/student",
        data: {
          // organization: organization,
          // code: emailVerifCode,
          name: fname + " " + lname,
          username: email,
          password: password,
          parentEmail: parentEmail,
          phone: phone,
          role: "student",
          grade: grade,
          token: token,
        },
        dataType: "sign_up",
      });

      console.log(signUpResp);
      console.log(error);

      //Sign up is locked and the user did not have a proper access token
      if (error && error.response.request.status === 401) {

        let token_err_dialog = {
          mainText: "Request Sent!",
          subText: "Thank you for signing up!\nWe are reviewing your account and will email you shortly with your new account!",
          button1Text: "Back To Home Page",
          onClickButton1: () => navigate("/login"),
        };
        if (token) {
          if (error.response.data.message === 'invalid') {
            token_err_dialog = {
              mainText: "Uh Oh!",
              subText: "Your link seems not to be working!\n Please contact support for a new link",
              button1Text: "Back To Home Page",
              onClickButton1: () => navigate("/login"),
            };
          }
          else if (error.response.data.message === 'expired') {
            token_err_dialog = {
              mainText: "Uh Oh!",
              subText: `Your link seems to be expired!\nPlease contact\n${error.response.data.email}\nfor a new link`,
              button1Text: "Back To Home Page",
              onClickButton1: () => navigate("/login"),
            };
          }
          else if (error.response.data.message === 'notFound') {
            token_err_dialog = {
              mainText: "Uh Oh!",
              subText: `We could not find your special link!\nPlease contact\n${error.response.data.email}\nfor a new link`,
              button1Text: "Back To Home Page",
              onClickButton1: () => navigate("/login"),
            };
          }
        }
        setOpenDialog(null);
        drop_dialog(() => setOpenDialog(token_err_dialog));
        setTimeout(() => {
          if (montedRef.current) navigate("/");
        }, 15000);
      }
      // After response first setOpenDialog is set to null to close previous dialog and together with the drop_dialog function the 2nd dialog has a drop down effect
      else if (signUpResp && signUpResp.status === 200) {
        Cookies.set('user', JSON.stringify(signUpResp.data), { expires: 1 });
        setOpenDialog(null);
        drop_dialog(() =>
          setOpenDialog({
            mainText: `Welcome ${fname}!`,
            subText: "Thank you for joining Super Me!",
          })
        );


        //Clear the query cache
        queryClient.clear();

        setTimeout(() => {
          navigate("/achievements");
        }, 4000);
      }
      else if (error && error.response.request.status === 409) {
        setOpenDialog(null);
        drop_dialog(user_exists_dialog);
      }
      else {
        setOpenDialog(null);
        drop_dialog(error_dialog);
      }
    } catch (error) {
      setOpenDialog(null);
      drop_dialog(error_dialog);
    }
  };

  const mainContent = (
    <form onSubmit={createAccount} className="sign-up-1">
      <div className="">
        <h4 className="heading_foam" style={{ color: "white" }}>Student Information</h4>
        <div className="row">
          <div className="col-lg-6 col-xl-6 col-xxl-6 col-sm-12">
            <div className="form-group ajax-field">
              <FormInput
                type={"text"}
                value={fname}
                placeholder={"First Name"}
                name={"first_name"}
                onChangeHandler={setFName}
                message={validation.name.message}
                pattern={validation.name.pattern}
              />
            </div>
          </div>
          <div className="col-lg-6 col-xl-6 col-xxl-6 col-sm-12">
            <div className="form-group ajax-field">
              <FormInput
                type={"text"}
                value={lname}
                placeholder={"Last Name"}
                name={"last_name"}
                onChangeHandler={setLName}
                pattern={validation.name.pattern}
                message={validation.name.message}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="pt-2">
        <div className="row">
          <div className="col-lg-6 col-xl-6 col-xxl-6 col-sm-12">
            <div className="form-group ajax-field">
              <FormInput
                type={"text"}
                value={email}
                placeholder={"Student Email"}
                name={"email"}
                onChangeHandler={setEmail}
                pattern={validation.email.pattern}
                message={validation.email.message}
              />
            </div>
          </div>
          <div className="col-lg-6 col-xl-6 col-xxl-6 col-sm-12">
            <div className="form-group ajax-field">
              <FormInput
                type={"number"}
                value={grade}
                placeholder={"Student Grade"}
                name={"grade"}
                onChangeHandler={setGrade}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="pt-2">
        <div className="row">
          <div className="col-lg-6 col-xl-6 col-xxl-6 col-sm-12">
            <div className="form-group ajax-field">
              <FormInput
                type={"password"}
                value={password}
                placeholder={"Create Password"}
                name={"password"}
                onChangeHandler={setPassword}
                pattern={validation.password.pattern}
                message={validation.password.message}
              />
            </div>
          </div>
          <div className="col-lg-6 col-xl-6 col-xxl-6 col-sm-12">
            <div className="form-group ajax-field">
              <FormInput
                type={"password"}
                value={confirmPass}
                placeholder={"Confirm Password"}
                name={"confirmPass"}
                onChangeHandler={setConfirmPass}
                pattern={confirmPassVali.pattern}
                message={confirmPassVali.message}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="py-lg-4">
        <h4 className="heading_foam" style={{ color: "white" }}>Parent Information</h4>
        <div className="row">
          <div className="col-lg-6 col-xl-6 col-xxl-6 col-sm-12 mt-1">
            <div className="form-group ajax-field">
              <FormInput
                type={"email"}
                value={parentEmail}
                placeholder={"Parent Email"}
                name={"parent_email_id"}
                onChangeHandler={setParentEmail}
                message={parentValidation.message}
                pattern={parentValidation.pattern}
              />
            </div>
          </div>
          {/* <div className="col-lg-6 col-xl-6 col-xxl-6 col-sm-12 mt-1">
            <div className="form-group ajax-field">
              <FormInput
                type={"tel"}
                placeholder={"Phone Number"}
                value={phone}
                name={"phone"}
                required={false}
                onChangeHandler={setPhone}
              />
            </div>
          </div> */}
        </div>
      </div>
      <div className="text-center">
        <button
          type="submit"
          className={main_css.basic_btnInv}
          style={{ padding: '5px 10px' }}
        >
          Continue
        </button>
      </div>
      <div className="text-center" style={{ paddingTop: '10px' }}>
        <Link to="/login" className="back_btn" style={{ color: "white" }}>
          Back to Log In
        </Link>
      </div>
    </form>
  );

  return (
    <>
      {openDialog && <DialogTemplate {...openDialog} />}

      {mainContent}
    </>
  );
};

export default StudentSignUpForm;
