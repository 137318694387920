import { useEffect, useState } from "react";
import Overlay from "../../../../Components/overlay/Overlay";
import { Mission, RenderableContent, SetState } from "../../../../types";

import Dialog from "../../../Components/dialog/Dialog";
import InfoTable from "../../../Components/infoTable/InfoTable";

import styles from "../creatorPage.module.scss";
import CategoryStep from "./CategoryStep";
import { CreatorData, CurrentCategories, Data } from "../types";
import CreateMissionSummary from "./CreateMissionSummary";
import TaskStep from "./TaskStep";
import TaskOptionStep from "./TaskOptionStep";
import TaskCompletedStep from "./TaskCompletedStep";
import useUpdateEditDialog from "../hooks/useUpdateEditDialog";
import useDeleteMission from "../hooks/useDeleteMission";
import OptionsIconsStep from "./OptionsIconsStep";
import CategoryIconStep from "./CategoryIconStep";

type Props = {
  setCreatorData: SetState<CreatorData | null>;
  currentMission: Mission;
  currentCategories: CurrentCategories;
  currentRewardTypes: string[];
  closeEditMissions: () => void;
};
type StepComponent = (props: any) => React.ReactNode;

type StepDetails = {
  steps: Array<StepComponent>;
  stepDataKeys: string[];
};

type StepsReference = {
  [stepsReferenceKey: string]: StepDetails;
};

const imgObj = {
  value: [
    {
      name: "",
      url: "",
    },
  ],
  err: "",
  new: false,
  display: styles.hidden,
  uploadReq: {
    required: false,
    nameRequired: false,
    min: 0,
    max: 0,
  },
};

const inputFieldObj = {
  value: "",
  err: "",
  display: styles.display,
};

const taskCompletedSubObj = {
  value: "",
  err: "",
  display: styles.hidden,
};

const EditMission = ({
  setCreatorData,
  currentMission,
  currentCategories,
  currentRewardTypes,
  closeEditMissions,
}: Props) => {
  let optionValue = [{ name: "", url: "" }];
  if (currentMission.options) {
    const optionKeys = Object.keys(currentMission.options);
    optionValue = optionKeys
      .map((option) => {
        return {
          name: option,
          url: currentMission.options![option].toString(),
        };
      })
      .filter((v) => v.url !== "");
  }
  const updateEditDialog = useUpdateEditDialog();
  const deleteMission = useDeleteMission();
  const [currentStepNum, setCurrentStepNum] = useState<number>(0);
  const [steps, setSteps] = useState<StepDetails | null>(null);
  const [content, setContent] = useState<RenderableContent>();
  const [data, setData] = useState<Data>({
    category: {
      value: [currentMission.category],
      err: "",
      img: {
        ...imgObj,
        value: [{ name: currentMission.image, url: currentMission.image }],
        display: styles.display,
      },
      inputField: { ...inputFieldObj, display: styles.hidden },
      requiredData: ["category"],
      requiredSubData: [],
    },
    categoryIcon: {
      value: [""],
      err: "",
      img: {
        ...imgObj,
        value: [{ name: currentMission.image, url: currentMission.image }],
        uploadReq: {
          required: true,
          nameRequired: false,
          min: 1,
          max: 1,
        },
      },
      inputField: { ...inputFieldObj },
      requiredData: [],
      requiredSubData: [],
    },
    task: {
      value: [currentMission.task],
      err: "",
      img: { ...imgObj },
      requiredData: ["task"],
      requiredSubData: [],
    },
    taskOption: {
      value: currentMission.optionsType === "image" ? ["image"] : ["message"],
      err: "",
      img: {
        ...imgObj,
      },
      requiredData: ["taskOption"],
      requiredSubData: [],
    },
    optionsIcons: {
      value: [""],
      err: "",
      img: {
        ...imgObj,
        value: optionValue,
        uploadReq: {
          required: true,
          nameRequired: true,
          min: 1,
          max: 10,
        },
      },
      requiredData: [],
      requiredSubData: [],
    },
    taskCompleted: {
      value: [currentMission.rewardType],
      err: "",
      img: { ...imgObj },
      requiredData: ["taskCompleted"],
      requiredSubData: [
        "rewardType",
        "rewardAmount",
        "maxAllowed",
        "resetPeriod",
      ],
      rewardType: { ...taskCompletedSubObj, value: currentMission.rewardType },
      rewardAmount: {
        ...taskCompletedSubObj,
        value: currentMission.rewardAmount,
        pattern: "^[0-9]+$",
      },
      maxAllowed: {
        ...taskCompletedSubObj,
        value: currentMission.maxAllowed.amount,
        pattern: "^[0-9]+$",
      },
      resetPeriod: {
        ...taskCompletedSubObj,
        value: currentMission.maxAllowed.period,
        pattern: "^[0-9]+$",
      },
    },
    addToGame: {
      value: [""],
      err: "",
      img: { ...imgObj },
      requiredData: [],
      requiredSubData: [],
    },
    createMissionSummary: {
      value: ["confirm"],
      err: "",
      img: { ...imgObj },
      requiredData: [],
      requiredSubData: [],
    },
  });
  const tableInfo = [
    {
      key: "category",
      stepsReferenceKey: "category",
      label: "Category",
      value: currentMission.category,
    },
    {
      key: "image",
      stepsReferenceKey: "category",
      label: "Category Icon",
      value: currentMission.image,
    },
    {
      key: "task",
      stepsReferenceKey: "task",
      label: "Task",
      value: currentMission.task,
    },
    {
      key: "taskOption",
      stepsReferenceKey: "taskOption",
      label: "Option Type",
      value: currentMission.optionsType === "image" ? "Image" : "Fill-In",
    },
    {
      key: "rewardAmount",
      stepsReferenceKey: "taskCompleted",
      label: "Reward Amount",
      value: currentMission.rewardAmount,
    },
    {
      key: "rewardType",
      stepsReferenceKey: "taskCompleted",
      label: "Reward Type",
      value: currentMission.rewardType,
    },
    {
      key: "maxAllowed.amount",
      stepsReferenceKey: "taskCompleted",
      label: "Max Allowed",
      value: currentMission.maxAllowed.amount,
    },
    {
      key: "maxAllowed.period",
      stepsReferenceKey: "taskCompleted",
      label: "Max Allowed Reset Period",
      value: currentMission.maxAllowed.period,
    },
  ];

  const handleRowClick = (rowInfo: {
    key: string;
    stepsReferenceKey: StepsKeys;
    value: string;
    label: string;
  }) => {
    setCurrentStepNum(1);
    const newSteps = stepsReferenceObj[rowInfo.stepsReferenceKey];
    setSteps({ steps: newSteps.steps, stepDataKeys: newSteps.stepDataKeys });
  };

  const EditTable = () => {
    const editTableDialogContent = (
      <>
        <div className={styles.mainText}>Click on a field to edit</div>
        <div className={styles.infoTableContainer}>
          <InfoTable
            tableTitle="Mission Details"
            route=""
            tableInfo={tableInfo}
            onSaveHandler={null}
            handleRowClick={handleRowClick}
          />
        </div>
        <div className={styles.deleteBtnContainer}>
          <button
            className={`${styles.btn} btn`}
            onClick={() =>
              deleteMission(
                currentMission._id,
                setCreatorData,
                setContent,
                closeEditMissions
              )
            }
          >
            Delete
          </button>
        </div>
      </>
    );
    return (
      <Dialog content={editTableDialogContent} />
    );
  };

  const stepsReferenceObj: StepsReference = {
    category: {
      steps: [EditTable, CategoryStep, CategoryIconStep, CreateMissionSummary],
      stepDataKeys: [
        "editTable",
        "category",
        "categoryIcon",
        "createMissionSummary",
      ],
    },
    task: {
      steps: [EditTable, TaskStep, CreateMissionSummary],
      stepDataKeys: ["editTable", "task", "createMissionSummary"],
    },
    taskOption: {
      steps: [
        EditTable,
        TaskOptionStep,
        OptionsIconsStep,
        CreateMissionSummary,
      ],
      stepDataKeys: [
        "editTable",
        "taskOption",
        "optionsIcons",
        "createMissionSummary",
      ],
    },
    taskCompleted: {
      steps: [EditTable, TaskCompletedStep, CreateMissionSummary],
      stepDataKeys: ["editTable", "taskCompleted", "createMissionSummary"],
    },
  };

  type StepsKeys = keyof typeof stepsReferenceObj;

  if (currentMission.optionsType === "image" && currentMission.options) {
    const optionKeys = Object.keys(currentMission.options);
    optionKeys.forEach((optionKey, index) => {
      tableInfo.push({
        key: `options.${optionKey}`,
        stepsReferenceKey: "taskOption",
        label: `Option ${index + 1}`,
        value: optionKey,
      });
    });
  }

  useEffect(() => {
    setContent(<EditTable />);
  }, []);

  useEffect(() => {
    if (!steps) return;
    if (currentStepNum === 0) {
      setContent(<EditTable />);
      return;
    }
    const stepDataKeys = steps.stepDataKeys;
    const CurrentStepComponent = steps.steps[currentStepNum];
    const currentStepJsx = (
      <CurrentStepComponent
        data={data}
        setData={setData}
        currentCategories={currentCategories}
        currentRewardTypes={currentRewardTypes}
        setCurrentStepNum={setCurrentStepNum}
      />
    );

    updateEditDialog(
      setCreatorData,
      currentMission,
      setContent,
      currentStepJsx,
      stepDataKeys[currentStepNum],
      data,
      setData,
      currentStepNum,
      setCurrentStepNum,
      stepDataKeys,
      closeEditMissions
    );
  }, [data, currentStepNum, steps]);

  return <Overlay content={content} closeHandler={closeEditMissions} />;
};

export default EditMission;
