import { Mission, RenderableContent, SetState } from "../../../../types";
import { CreatorData, Data, TaskCompletedSubObj, Update } from "../types";
import ApiService from "../../../../Services/apiServiceT";

import ActionSuccessStatus from "../../../Components/actionSuccessStatus/ActionSuccessStatus";

const useEditMission = () => {
  const EditMission = async (
    setCreatorData: SetState<CreatorData | null>,
    currentMission: Mission,
    data: Data,
    stepDataKeys: string[],
    setDialogContent: SetState<RenderableContent>,
    closeEditMissions: () => void
  ) => {
    const apiService = new ApiService();
    const updates: Update = {};

    stepDataKeys.forEach((key) => {
      if (key === "editTable" || key === "creatMissionSummary") return;
      const missionKey = key as keyof Mission;
      const dataKey = key as keyof Data;

      // Category Update
      if (key === "category") {
        if (data[dataKey].value[0] === "new") {
          if ("inputField" in data[dataKey]) {
            const inputFieldData = data[dataKey].inputField as {
              [key: string]: string;
            };
            updates["missions.$.category"] = inputFieldData.value;
          }
          updates["missions.$.image"] = data.categoryIcon.img.value[0].url;
        } else {
          if (data[dataKey].value[0] !== currentMission[missionKey]) {
            updates["missions.$.category"] = data[dataKey].value[0];
          }
          if (data.categoryIcon.img.value[0].url !== currentMission.image) {
            updates["missions.$.image"] = data.categoryIcon.img.value[0].url;
          }
        }
      }

      // Task Update
      else if (key === "task") {
        if (data[dataKey].value[0] !== currentMission[missionKey]) {
          updates["missions.$.task"] = data[dataKey].value[0];
        }
      }

      // Task Option Update
      else if (key === "taskOption") {
        if (data.taskOption.value[0] === "message") {
          updates["missions.$.optionsType"] = "message";
          updates["missions.$.options"] = "deleteField";
        } else {
          console.log(updates["missions.$.options"]);
          updates["missions.$.options"] = "";

          updates["missions.$.optionsType"] = "image";
          updates["missions.$.options"] = {};

          data.optionsIcons.img.value.forEach((img) => {
            const imgName = img.name;
            if (typeof updates["missions.$.options"] === "object") {
              if (img.url === "" || !img.url) return;
              updates["missions.$.options"][imgName] = img.url;
            }
          });
        }
      } else if (key === "taskCompleted") {
        if (
          "rewardType" in data[dataKey] &&
          "rewardAmount" in data[dataKey] &&
          "maxAllowed" in data[dataKey] &&
          "resetPeriod" in data[dataKey]
        ) {
          const rewardTypeData = data[dataKey]
            .rewardType as unknown as TaskCompletedSubObj;
          const rewardAmountData = data[dataKey]
            .rewardAmount as TaskCompletedSubObj;
          const maxAllowedData = data[dataKey]
            .maxAllowed as TaskCompletedSubObj;
          const resetPeriodData = data[dataKey]
            .resetPeriod as TaskCompletedSubObj;

          if (rewardTypeData.value?.toString() !== currentMission.rewardType) {
            updates["missions.$.rewardType"] = rewardTypeData.value!;
          }
          if (rewardAmountData.value !== currentMission.rewardAmount) {
            updates["missions.$.rewardAmount"] = rewardAmountData.value!;
          }
          if (maxAllowedData.value !== currentMission.maxAllowed.amount) {
            updates["missions.$.maxAllowed"] = {
              amount: maxAllowedData.value!,
              period: currentMission.maxAllowed.period,
            };
          }
          if (resetPeriodData.value !== currentMission.maxAllowed.period) {
            if (typeof updates["missions.$.maxAllowed"] === "object") {
              updates["missions.$.maxAllowed"].period = resetPeriodData.value!;
            } else {
              updates["missions.$.maxAllowed"] = {
                amount: currentMission.maxAllowed.amount,
                period: resetPeriodData.value!,
              };
            }
          }
        }
      }
    });

    const editMission = async () => {
      if (Object.keys(updates).length > 0) {
        try {
          const [editResp, err] = await apiService.patch({
            path: "/update_mission",
            dataType: "admin",
            data: {
              missionId: currentMission._id,
              updates,
            },
          });
          if (!err) {
            return editResp;
          } else {
            console.log(err);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    const editResp = await editMission();
    let message: RenderableContent;
    if (editResp?.status === 200) {
      message = (
        <>
          Completed <br></br> Mission Updated <br></br> Successfully
        </>
      );
      if (setCreatorData) {
        const creatorDataMissionEdit: Update = {};
        const updateKeys = Object.keys(updates);
        updateKeys.forEach((key) => {
          let newData = updates[key];
          if (
            key === "missions.$.options" &&
            updates["missions.$.options"] === "deleteField"
          ) {
            newData = "";
          }

          const newKey = key.replace("missions.$.", "");
          creatorDataMissionEdit[newKey] = newData;
        });
        setCreatorData((prev) => {
          let missionToUpdate: Mission | null = null;
          const prevMissions: Mission[] = [];
          prev?.missions.forEach((mission) => {
            if (mission._id === currentMission._id) {
              missionToUpdate = { ...mission, ...creatorDataMissionEdit };
            } else {
              prevMissions.push(mission);
            }
          });
          if (!missionToUpdate) return prev!;
          return {
            ...prev!,
            missions: [...prevMissions, missionToUpdate],
          };
        });
      }
    }

    if (!message) {
      message = (
        <>
          Something went wrong. <br></br> Please try again.
        </>
      );
    }

    setDialogContent(
      <ActionSuccessStatus message={message} closeHandler={closeEditMissions} />
    );
  };
  return EditMission;
};

export default useEditMission;
